import classNames from "classnames";
import React from "react";
import { AppBtn } from "../ui/buttons";

interface ConfirmationProps {
  toggle: () => void;
  show: boolean;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({ toggle, show, onConfirm }) => {
  const popupClassnames = classNames(
    "absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 transform transition-all ease-in-out duration-500 -mx-5 lg:mx-0 blur-3",
    {
      "opacity-0 pointer-events-none": !show,
      "opacity-100 translate-y-0": show,
    }
  );

  return (
    <div className={popupClassnames}>
      <div className="absolute w-[90%] bg-white border border-grey-divider border-opacity-50 rounded-10 shadow-card top-6 left-[5%] z-50">
        <button
          type="button"
          onClick={toggle}
          className={`no-outline h-6 w-6 rounded-full bg-grey-fields-100 border border-grey-divider ml-auto transition-all text-black-muted hover:border-primary-500 hover:text-primary-300 flex items-center justify-center absolute top-2 right-2 cursor-pointer z-[10]`}
        >
          <svg width="45%" viewBox="0 0 19.528 19.529">
            <g transform="translate(-314.611 -73.746)">
              <line
                y2="25.617"
                transform="translate(333.432 74.454) rotate(45)"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              />
              <line
                y2="25.617"
                transform="translate(315.318 74.454) rotate(-45)"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              />
            </g>
          </svg>
        </button>
        <div className="px-6 py-7.5 text-center">
          <span className="text-base text-black text-center font-medium">
            Are you sure you want <br /> to cancel this payment?
          </span>
        </div>
        <div className="border-t border-grey-divider border-opacity-50 py-3 px-6 flex items-start">
          <AppBtn color="neutral" isBlock className="flex-1 mr-2.5" size="sm" onClick={toggle}>
            Don&apos;t cancel
          </AppBtn>
          <AppBtn color="danger" isBlock className="flex-1" size="sm" onClick={onConfirm}>
            Yes, cancel
          </AppBtn>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
