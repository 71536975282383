import React, { useEffect, VoidFunctionComponent } from "react";
import ConfirmationModal from "./confirmation-modal";
import { useModals } from "../hooks/useModals";
import { AppBtn } from "../ui/buttons";

interface IProps {
  goBack: () => void;
  triggerError: VoidFunction;
  handleSuccess: VoidFunction;
  paymentSuccessful: boolean;
  waitFor?: number;
}

const PaymentAwaitingConfirmation: React.FC<IProps> = ({
  triggerError,
  paymentSuccessful,
  handleSuccess,
  waitFor,
  goBack,
}) => {
  const { modals, toggleModal } = useModals(["cancel_payment"]);

  useEffect(() => {
    //DOING THIS TO COUNTER CASES WHERE WEBSOCKT IS CALLED BEFORE PAYMENT WIDGET IS CLOSED
    if (paymentSuccessful) {
      handleSuccess();
      return;
    }

    const interval = setTimeout(() => {
      triggerError();
    }, (waitFor ?? 3) * 60 * 1000);

    return () => clearTimeout(interval);
  }, [waitFor, paymentSuccessful]);

  return (
    <>
      <div className="flex flex-col items-center py-30">
        <figure className="text-primary-500">
          <div className="spinner spinner--lg"></div>
        </figure>
        <span className="text-1sm sm:text-base font-medium text-placeholder mt-3.75 text-center">
          Awaiting payment <br /> confirmation
        </span>
        <AppBtn className="mt-3.75" color="neutral-danger" size="sm" onClick={() => toggleModal("cancel_payment")}>
          Cancel Payment
        </AppBtn>
      </div>
      <ConfirmationModal
        onConfirm={goBack}
        show={modals.cancel_payment.show}
        toggle={() => toggleModal("cancel_payment")}
      />
    </>
  );
};

export default PaymentAwaitingConfirmation;
