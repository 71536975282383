// components/payments/momo-payment.tsx

import React, { useEffect, useState } from "react";
import { useRequest } from "../../api/utils";
import { InitiateMoMoPayment } from "../../api/payments";
import useSteps from "../hooks/useSteps";
import { AppBtn } from "../ui/buttons";
import CircularProgress from "../ui/circular-progress";
import ConfirmationModal from "./confirmation-modal";
import { getFieldvalues, toCurrency, toNaira } from "../../assets/js/utils/functions";
import { toast } from "../ui/toast";
import { useModals } from "../hooks/useModals";
import { InputField, SelectDropdown } from "../ui/form-elements";
import { useFormik } from "formik";
import * as Yup from "yup";
import { INVOICE_STATUSES } from "@/assets/interfaces";

interface IProps {
  goBack: () => void;
  paymentData: {
    invoice: string;
    total_amount: number;
    reference: string;
    networks: string[];
  };
  triggerError: VoidFunction;
  invoiceData: {
    preview_amount: number;
    invoice: string;
    initial_status: INVOICE_STATUSES;
    currency: string;
    storeName?: string;
  };
}

enum STEPS {
  ENTER_DETAILS = "ENTER_DETAILS",
  WAITING = "WAITING",
}

const MoMoPayment: React.FC<IProps> = ({ goBack, paymentData, triggerError, invoiceData }) => {
  const { modals, toggleModal } = useModals(["cancel_transfer"]);
  const [progress, setProgress] = useState(0);
  const [timeWaited, setTimeWaited] = useState(0);
  const { changeStep, step } = useSteps([STEPS.ENTER_DETAILS, STEPS.WAITING], 0);

  const paymentValidityTime = 25 * 60 * 1000; // 25 minutes

  const initiateMoMoPaymentRequest = useRequest<any>(InitiateMoMoPayment);

  useEffect(() => {
    if (step === STEPS.WAITING) {
      let timeElapsed = 0;

      const interval = setInterval(() => {
        if (timeElapsed < paymentValidityTime) {
          timeElapsed += 1000;
          setTimeWaited(timeElapsed);
          setProgress(timeElapsed / paymentValidityTime);
        } else {
          clearInterval(interval);
          triggerError();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [step]);

  const validationSchema = Yup.object().shape({
    provider: Yup.string().required("Please select a provider"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
      .min(10, "Mobile number must be at least 10 digits")
      .max(15, "Mobile number must be at most 15 digits"),
  });

  const form = useFormik({
    initialValues: {
      provider: "",
      mobileNumber: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { provider, mobileNumber } = values;
      // Initiate MOMO payment
      const [res, err] = await initiateMoMoPaymentRequest.makeRequest({
        network: provider,
        payment_reference: paymentData.reference,
        msisdn: mobileNumber,
      });

      if (res) {
        changeStep(STEPS.WAITING);
      } else {
        // Handle initiation error
        toast.error({ title: "Initiation Failed", message: err?.message ?? "Please try another payment method" });
      }
    },
  });

  const networks = paymentData.networks || [];

  return (
    <div className="">
      {/* Header and Back Button */}
      <div className="relative flex flex-col items-center border-b border-grey-border border-opacity-50 mb-5 xl:mb-7.5">
        <button
          className="absolute top-0 left-0 hidden lg:flex items-center justify-center w-12.5 h-12.5 rounded-10 text-primary-500 bg-grey-fields-200"
          onClick={() => toggleModal("cancel_transfer")}
        >
          {/* Back icon */}
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M13 7H1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M7 1L1 7L7 13"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <figure className="flex items-center justify-center w-15 h-15 bg-accent-orange-500 rounded-full my-3.75 text-white">
          {/* prettier-ignore */}
          <svg width="50%" height="50%" viewBox="0 0 200 200" fill="none">
            <path d="M86.833 111.834H110.5L113.166 88.167H89.4997L86.833 111.834Z" fill="currentColor"/>
            <path d="M134.917 16.667H65.0837C34.7503 16.667 16.667 34.7503 16.667 65.0837V134.834C16.667 165.25 34.7503 183.334 65.0837 183.334H134.834C165.167 183.334 183.25 165.25 183.25 134.917V65.0837C183.334 34.7503 165.25 16.667 134.917 16.667ZM156.834 88.167H125.417L122.75 111.917H150.834C154.167 111.917 156.917 114.667 156.917 118C156.917 121.334 154.167 124.084 150.834 124.084H121.417L118 154.584C117.667 157.667 115 160 111.917 160C111.667 160 111.5 160 111.25 160C107.917 159.667 105.5 156.584 105.834 153.25L109.084 124.084H85.417L82.0003 154.584C81.667 157.667 79.0003 160 75.917 160C75.667 160 75.5003 160 75.2503 160C71.917 159.667 69.5003 156.584 69.8337 153.25L73.0837 124.084H43.167C39.8337 124.084 37.0837 121.334 37.0837 118C37.0837 114.667 39.8337 111.917 43.167 111.917H74.5837L77.2503 88.167H49.167C45.8337 88.167 43.0837 85.417 43.0837 82.0837C43.0837 78.7503 45.8337 76.0003 49.167 76.0003H78.5837L82.0003 45.5003C82.3337 42.167 85.417 39.7503 88.7503 40.0837C92.0837 40.417 94.5003 43.5003 94.167 46.8337L90.917 76.0003H114.584L118 45.5003C118.417 42.167 121.417 39.7503 124.75 40.0837C128.084 40.417 130.5 43.5003 130.167 46.8337L126.917 76.0003H157C160.334 76.0003 163.084 78.7503 163.084 82.0837C163.084 85.417 160.167 88.167 156.834 88.167Z" fill="currentColor"/>
          </svg>
        </figure>
        <h1 className="flex flex-col items-center justify-center text-black text-2lg xl:text-2xl font-medium mb-3.75 xl:mb-5 !leading-snug">
          <span className="block">Pay with Mobile Money</span>
          <span className="font-bold">{toCurrency(toNaira(paymentData.total_amount), invoiceData.currency)}</span>
        </h1>
      </div>
      {/* Form and Waiting Steps */}
      {step === STEPS.ENTER_DETAILS && (
        <form
          onSubmit={form.handleSubmit}
          className="w-full px-4 border border-grey-divider py-6.25 mb-3.75 rounded-15"
        >
          <div className="mb-4">
            <SelectDropdown
              label="Mobile Money Network"
              options={networks.map((network) => ({ text: network, value: network }))}
              {...getFieldvalues("provider", form)}
            />
          </div>
          <div className="mb-4">
            <InputField label="Mobile Number" {...getFieldvalues("mobileNumber", form)} />
          </div>
          <AppBtn isBlock size="lg" type="submit" disabled={initiateMoMoPaymentRequest.isLoading}>
            {initiateMoMoPaymentRequest?.isLoading ? "Initiating..." : "Proceed"}
          </AppBtn>
        </form>
      )}
      {step === STEPS.WAITING && (
        <div className="flex flex-col items-center mb-5">
          <span className="bg-grey-fields-100 text-xs font-semibold rounded-5 px-2.5 py-1.5 text-primary-500">
            Waiting for Payment
          </span>
          <span className="inline-block text-dark text-xs text-center mt-1.5 mb-2.5 max-w-[220px]">
            We&apos;re waiting for you to approve the payment on your phone.
          </span>
          <div className="mt-2.5 bg-grey-fields-100 h-1.5 w-full max-w-[200px] rounded-10 overflow-hidden">
            <div className="bg-accent-green-500 h-full rounded-10" style={{ width: `${progress * 100}%` }}></div>
          </div>
          <div className="flex mt-5">
            {/* <figure className="flex items-center justify-center w-7.5 h-7.5 rounded-10 bg-white text-accent-green-500">
              <CircularProgress progress={progress} width={20} outline={3} />
            </figure> */}
            <div className="flex flex-col justify-center text-xxs ml-1.25 text-dark text-center">
              <span className="block leading-snug">Payment expires in</span>
              <span className="block font-semibold leading-snug">{formatTime(paymentValidityTime - timeWaited)}</span>
            </div>
          </div>
        </div>
      )}
      {/* Cancel Button */}
      <div className="lg:hidden border-t border-grey-border border-opacity-50 py-3.75 lg:py-0 lg:border-none flex flex-col space-y-2.5">
        <div className="w-full">
          <AppBtn
            isBlock
            size="lg"
            className="flex-1 mr-2.5"
            color="neutral"
            onClick={() => toggleModal("cancel_transfer")}
          >
            Cancel Payment
          </AppBtn>
        </div>
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        onConfirm={goBack}
        show={modals.cancel_transfer.show}
        toggle={() => toggleModal("cancel_transfer")}
      />
    </div>
  );
};

function formatTime(time: number) {
  const timeInSecs = time / 1000;
  const mins = Math.floor(timeInSecs / 60);
  const secs = Math.floor(timeInSecs % 60);
  return `${mins} mins ${secs} secs`;
}

export default MoMoPayment;
